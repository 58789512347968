import React, { useState } from 'react';
import Logo from '../graphics/logo';
import Icon from "../graphics/icon";
import InternalLink from "../internal-link";

const Nav = (props) => {

    const navLeft = [
        {
            id: "1",
            name: "Oferta",
            link: "#oferta",
        },
        {
            id: "2",
            name: "O nas",
            link: "#o-nas",
        },
    ];

    const navRight = [
        {
            id: "1",
            name: "Opinie",
            link: "#opinie",
        },
        {
            id: "2",
            name: "Kontakt",
            link: "#kontakt",
        },
    ];

    const navMobile = [
        {
            id: "1",
            name: "Oferta",
            link: "#oferta",
        },
        {
            id: "2",
            name: "O nas",
            link: "#o-nas",
        },
        {
            id: "3",
            name: "Opinie",
            link: "#opinie",
        },
        {
            id: "4",
            name: "Kontakt",
            link: "#kontakt",
        },
    ];

    const [isExpanded, toggleExpansion] = useState(false)

    return (
        <nav className="px-4 py-6 mx-auto lg:py-8 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 text-gray-800">
            <div className="relative flex items-center justify-between lg:justify-center lg:space-x-16">
                <ul className="hidden items-center space-x-8 lg:flex">
                    {navLeft.map((item, id) => (
                        <li key={id}>
                            <InternalLink name={item.name} link={item.link} aria-label={item.link} title={item.link} />
                        </li>
                    ))}
                </ul>

                <a
                    href="/"
                    aria-label="Company"
                    title="Company"
                    className="inline-flex items-center">
                    <Logo className="fill-current" />
                </a>
                <ul className="hidden items-center space-x-8 lg:flex">
                    {navRight.map((item, id) => (
                        <li key={id}>
                            <InternalLink name={item.name} link={item.link} aria-label={item.link} title={item.link} />
                        </li>
                    ))}
                </ul>
                {/* Mobile menu */}
                <div className="lg:hidden">
                    <button
                        onClick={() => toggleExpansion(!isExpanded)}
                        aria-label="Open Menu"
                        title="Open Menu"
                        className="p-2 -mr-1 transition duration-200 rounded-lg focus:outline-none focus:shadow-outline hover:bg-peru-50 focus:bg-peru-50">
                        <Icon
                            fill='none'
                            iconName="icon-menu"
                            className='stroke-current inline-block h-8 w-8'>
                        </Icon>
                    </button>
                    {/* Mobile menu dropdown */}
                    <div className={`absolute top-0 left-0 w-full ${isExpanded ? `block shadow-3xl` : `hidden`}`}>
                        <div className="p-5 bg-gray-50 border rounded-lg shadow-sm space-y-8">
                            <div className="flex items-center justify-between">
                                <a
                                    href="/"
                                    aria-label="Company"
                                    title="Company"
                                    className="inline-flex items-center">
                                        <Logo className="fill-current" />
                                </a>
                                <button
                                    onClick={() => toggleExpansion(!isExpanded)}
                                    aria-label="Close Menu"
                                    title="Close Menu"
                                    className={` ${isExpanded ? `transition transform-180` : `p-2 -mt-2 -mr-2 transition duration-200 rounded-lg hover:bg-peru-50 focus:bg-peru-50 focus:outline-none focus:shadow-outline`}`}>
                                    <Icon
                                        fill='none'
                                        iconName="icon-close"
                                        className='stroke-current inline-block h-8 w-8'>
                                    </Icon>
                                </button>
                            </div>
                            <nav>
                                <ul className="space-y-8">
                                    {navMobile.map((item, id) => (
                                        <li key={id}>
                                            <InternalLink name={item.name} link={item.link} aria-label={item.link} title={item.link} />
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Nav
