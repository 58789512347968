import React from "react";

const InternalLink = ({ name, link }) => {
    return (
        <a
            href={link}
            aria-label={link}
            title={link}
            className="cursor-pointer select-none font-light text-sm uppercase px-3 py-1 tracking-wider transition-colors duration-200 hover:text-peru-400">
            {name}
        </a>
    )
};

export default InternalLink