import React from 'react'
import { withPrefix } from 'gatsby'

const Icon = (props) => {
    return (
        <svg className={props.className} fill={props.fill}>
            <use xlinkHref={withPrefix(`sprite.svg#${props.iconName}`)}></use>
        </svg>
    )
}

export default Icon
