import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import CookieConsent from 'react-cookie-consent'

import Nav from '../components/sections/nav';
import Footer from '../components/sections/footer';


const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          datoCmsSite {
            faviconMetaTags {
              ...GatsbyDatoCmsFaviconMetaTags
            }
            globalSeo {
              siteName
            }
          }
        }
      `}

      render={(data) => (
        <div className='min-h-full font-sans bg-gray-50'>
          <HelmetDatoCms
            favicon={data.datoCmsSite.faviconMetaTags}
            htmlAttributes={{ lang: 'pl' }} />
          <Nav siteName={data.datoCmsSite.globalSeo.siteName} />
          <main className='min-h-screen'>{children}</main>
          <Footer siteName={data.datoCmsSite.globalSeo.siteName} />
          <CookieConsent
            containerClasses='fixed flex flex-wrap md:flex-nowrap justify-around items-center p-4 lg:p-8 space-y-4 select-none w-full small shadow-inner z-50 font-sans bg-gray-100'
            buttonClasses='py-2 px-4 flex justify-center items-center bg-trasparent border border-peru-500 focus:ring-peru-200 focus:ring-offset-peru-100 text-peru-500 transition ease-in duration-200 text-center font-light text-sm uppercase tracking-wider shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg'
            disableStyles='true'
            buttonText='Akceptuj i zamknij'
            cookieName='gatsby-gdpr-google-analytics'
          >
            <p>
              Strona <span className="font-medium to-gray-800">{data.datoCmsSite.globalSeo.siteName}.pl</span> korzysta z plików Cookies, aby zapewnić najlepszą jakość użytkowania.
              <Link className='text-peru-500 tracking-wider' to='/polityka-prywatnosci'>{' '}Dowiedz się więcej{' '}</Link>
              lub zamknij ten komunikat.
            </p>
          </CookieConsent>
        </div>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
