import React from 'react'
import Logo from '../graphics/logo';
import Container from '../container';
import Header3 from "../header3";
import Paragraph from "../paragraph";
import InternalLink from "../internal-link";
import Icon from "../graphics/icon";
// import Obfuscate from 'react-obfuscate';

const Footer = (props) => {

    const socialLinks = [
        {
            id: "1",
            name: "facebook",
            link: "https://www.facebook.com/KwiatyiwiecejUW",
        },
        {
            id: "2",
            name: "instagram",
            link: "https://www.instagram.com/kwiatyiwiecej/",
        },
    ];

    return (
        <footer id="kontakt">
            <Container className="text-gray-800 space-y-4">
                <div className="grid gap-10 row-gap-6 sm:grid-cols-2 lg:grid-cols-4">
                    <div className="sm:col-span-2 space-y-4">
                        <a
                            href="/"
                            aria-label="Go home"
                            title="Company"
                            className="inline-flex items-center">
                                <Logo className="fill-current" />
                        </a>
                        <Paragraph className="lg:max-w-sm">
                            Pomożemy Ci dostrzec piękno w najdrobniejszych szczegółach, które tworzą całość.
                        </Paragraph>
                    </div>

                    <div
                        className="space-y-2 to-gray-800"
                        itemScope=''
                        itemType='http://schema.org/LocalBusiness'>

                        <Header3>Kontakt</Header3>

                        <div className="flex flex-col text-base">
                            <p className="mr-1 font-medium">Telefon:</p>
                            <a
                                href="tel:+48 537 177 472"
                                aria-label="Telefon"
                                title="Telefon"
                                className="transition-colors duration-300 hover:text-peru-400">+48 537 177 472
                            </a>
                        </div>

                        <div className="flex flex-col text-base">
                            <p className="mr-1 font-medium">Email:</p>
                            {/* <Obfuscate style={{ display: 'inline-block' }} email="-mail@mailbox.org" /> */}
                            <a
                                href="mailto:pendula100@gmail.com"
                                aria-label="Adres email"
                                title="Adres email"
                                className="transition-colors duration-300 hover:text-peru-400">
                                    pendula100@gmail.com
                            </a>
                        </div>

                        <div className="flex flex-col text-base">
                            <p className="mr-1 font-medium">Adres:</p>
                            <p className='mr-1' itemProp='name'>
                                Kwiaty i więcej
                            </p>
                            <a
                                href="https://www.google.com/maps/place/Kwiaty+i+wi%C4%99cej.../@50.0156767,19.8853511,15z/data=!4m5!3m4!1s0x0:0x80e27c749a1fd9a2!8m2!3d50.0156704!4d19.8853751"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Nasz adres"
                                title="Nasz adres"
                                className="transition-colors duration-300 hover:text-peru-400"
                                itemProp='address'
                                itemScope=''
                                itemType='http://schema.org/PostalAddress'>
                                <span className="block" itemProp='streetAddress'>
                                    Karola Bunscha 14
                                </span>
                                <span itemProp='postalCode'>
                                    30-392
                                </span>
                                <span itemProp='addressLocality'>
                                    Kraków
                                </span>
                            </a>
                        </div>
                        <div className="flex flex-col text-base">
                            <p className="mr-1 font-medium">Godziny otwarcia:</p>
                            <span>
                                Poniedziałek-piątek 10 – 19
                            </span>
                            <span>
                                Sobota 9 – 15
                            </span>
                            <span>
                                Niedziela 10 – 14
                                <br/>
                                …lub według życzenia
                            </span>
                        </div>
                    </div>

                    <div className="space-y-4">
                        <Header3>Social media</Header3>
                        <div className="flex items-center space-x-3">
                            {socialLinks.map((item, id) => (
                                <a href={`${item.link}`} title="item.name" target="_blank" rel="noopener noreferrer" className="transition-colors duration-300 hover:text-peru-400" key={id}>
                                    <Icon fill='none' iconName={`icon-${item.name}`} className='stroke-current inline-block h-8 lg:h-10 w-8 lg:w-10'>
                                    </Icon>
                                </a>
                            ))}
                        </div>
                    </div>
                </div >

                <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
                    <Paragraph>
                        © Copyright {new Date().getFullYear()},{' '}{props.siteName}
                    </Paragraph>
                    <ul className="flex flex-col space-y-2 sm:space-y-0 sm:space-x-5 sm:flex-row">
                        <li>
                            <InternalLink link={`/polityka-prywatnosci`} name={`Polityka prywatności`} />
                        </li>
                    </ul>
                </div>
            </Container>
        </footer>
    )
}

export default Footer
